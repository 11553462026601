















  import { ServerResponse } from '@/services/response.types';
  import { AddressBookModel } from '@/store/auth/types';
  import { CityModel, ProvinceModel } from '@/store/location/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Model, Prop, Watch } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'AddressSelector',
    components: {
      addressBook: () => import('@/views/profile/addressBook.vue'),
    },
    mixins: [],
  })
  export default class AddressSelector extends Vue {
    @Action('GetProvince', { namespace: 'location' }) GetProvince!: (params?: { state_id: any }) => Promise<ServerResponse>;
    @Action('GetCity', { namespace: 'location' }) GetCity!: (params?: { state_id: any }) => Promise<ServerResponse>;
    @Action('GetAddresBook', { namespace: 'auth' }) GetAddresBook!: (params?: any) => Promise<ServerResponse>;

    @State('province', { namespace: 'location' }) province!: ProvinceModel[];
    @State('city', { namespace: 'location' }) city!: CityModel[];
    @State('OnLoadAddress', { namespace: 'auth' }) onLoad: boolean;
    @State('address', { namespace: 'auth' }) addressList: AddressBookModel[];

    @Model('input', { type: String }) address_id!: string;

    @Prop({ required: true }) label!: string;
    @Prop({ required: true }) name!: string;

    public showListAddress = false;
    public selectedAddress: AddressBookModel = new AddressBookModel();
    public addressPreview = ''


    @Watch('showListAddress') OnChangeShowListAddress(show) {
      if (show) this.GetAddress();
    }

    @Watch("addressList") OnChangeAddress(addrL: AddressBookModel[]){
      if(this.selectedAddress.address_id.length === 0) this.selectedAddress = addrL[0]
      this.addressPreview = this.format()
    }

    public created(): void {
      this.GetAddress();
    }

    /**
     * GetAddress
     */
    public GetAddress() {
      while (!this.onLoad && this.addressList.length === 0) {
        this.GetAddresBook().then(() => {
          this.selectedAddress = this.addressList[0];
        });
      }

      this.addressPreview = this.format()
    }

    /**
     * format
     */
    public format(): string {
      return [this.selectedAddress.address, this.selectedAddress.province_name, this.selectedAddress.city_name].filter((v) => v?.length > 1).join(', ') + ` ${this.selectedAddress.zipcode}`.trimRight();
    }

    /**
     * OnSelected
     */
    public OnSelected(address: AddressBookModel) {
      this.selectedAddress = address;
      this.addressPreview = this.format()
      this.$emit('input', address.address_id)
      this.showListAddress = false;
    }
  }
